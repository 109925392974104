export default {
    namespaced: true,

    state: {
        advancedFlow: false,
        continuousFlow: false,
        allFlows: null,
        browserBackButton: true,
        currentFlows: null,
        currentQuestion: 1,
        equalFlow: true,
        nextQuestionStep: null,
        progressBar: null,
        questionCount: 1,
        questionFlow: [1],
        questionsData: null,
        storedAnswers: {},
        totalFlowLength: 4,
        zipcode: {
            previousValue: '',
            inputContainer: null,
            button: null,
            noBranchDialog: '',
            preventCloseDialog: false,
            nextQuestion: '',
        },
        storeLocator: {},
        extradata: [],
        advancedWorktypeSettings: [],
    },

    mutations: {
        setQuiz(state, {key, value}) {
            state[key] = value;
        },

        setZipcode(state, {key, value}) {
            state.zipcode[key] = value;
        },

        addExtradata(state, {name, data}) {
            if (state.extradata[name]) {
                console.error(`${name} already exists! Please use the correct index for the question.`);
            }

            state.extradata[name] = data;
        },

        storeAnswer(state, {key, value}) {
            state.storedAnswers[key] = value;
        },

        storeExtradata(state, {name, key, value}) {
            for (const [dataKey, data] of Object.entries(state.extradata)) {
                if (dataKey === name) data[key] = value;
            }
        },
    },

    getters: {
        getExtraData: (state) => (key, name) => {
            for (const [dataKey, data] of Object.entries(state.extradata)) {
                if (dataKey === name) return data[key];
            }

            return null;
        },

        getAnswer: (state) => (key) => {
            return state.storedAnswers[key];
        },
    },
};
