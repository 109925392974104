import { defineStore } from 'pinia';
import {ref} from 'vue';

export const usePageStore = defineStore('page', () =>{
    const preventScroll = ref(false);
    const steps = ref([]);
    const step = ref(1);
    const totalSteps = ref(0);

    const notificationText = ref('');
    const productType = ref('');
    const leadData = ref({});
    const data = ref({});
    const countryCode = ref('');
    const language = ref('');
    const customer = ref('');
    const zipcodeLists = ref({});
    const preview = ref('');
    const routes = ref({});
    const translations = ref({});
    const pageGroupType = ref('');
    const isLanding = ref(false);
    const domain = ref('');
    const needsTrustedForm = ref(false);
    
    const setupPageStore = (props, data) => {
        notificationText.value = props.notificationText;
        productType.value = props.productType;
        leadData.value = props.leadData;
        data.value = data;
        countryCode.value = props.page.countryCode;
        language.value = props.page.language;
        customer.value = props.page.customer;
        zipcodeLists.value = props.zipcodeLists;
        preview.value = props.tag;
        routes.value = props.routes;
        translations.value = props.translations;
        pageGroupType.value = props.page.pageGroupType;
        isLanding.value = props.isLanding;
        domain.value = props.domain;
        needsTrustedForm.value = props.customer.needsTrustedForm;
    }

    return {
        countryCode,
        language,
        preventScroll,
        steps,
        step,
        translations,
        totalSteps,
        notificationText,
        setupPageStore,
    };
});
