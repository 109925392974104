import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

export default function(vue) {
  if (window.Bugsnag.enabled) {
    const blacklistedScriptDomains = [
      'googletagmanager.com',
    ];

    Bugsnag.start({
      apiKey: window.Bugsnag.apiKey,
      appVersion: window.Bugsnag.appVersion,
      releaseStage: window.Bugsnag.releaseStage,
      plugins: [new BugsnagPluginVue()],
      enabledErrorTypes: {
        unhandledExceptions: true,
        unhandledRejections: false, // no "419" or "500 server error" errors
      },
      onError: function (event) {
        for (const [key, value] of Object.entries(window.Bugsnag.metadata)) {
          event.addMetadata(key, value);
        }

        if (!event.errors) {
          return;
        }

        for (const error of event.errors) {
          if (!error || !error.stacktrace) {
            continue;
          }

          for (const stacktrace of error.stacktrace) {
            if (!stacktrace || !stacktrace.file) {
              continue;
            }

            for (const blacklistedDomain of blacklistedScriptDomains) {
              if (stacktrace.file.indexOf(blacklistedDomain) !== -1) {
                // error is coming from a script from a blacklisted domain
                return false;
              }
            }
          }
        }
      },
    });

    vue.use(Bugsnag.getPlugin('vue'));
  }
}
