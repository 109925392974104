export default {
    namespaced: true,

    state: () => ({
        step: {},
        question: {},
        beforeFormSubmit: [],
    }),

    mutations: {
        addStepChangeEvent(state, { step, operation }) {
            if(!state.step[step]) state.step[step] = [];

            state.step[step].push(operation);
        },

        addQuestionChangeEvent(state, { question, operation }) {
            if(!state.question[question]) state.question[question] = [];

            state.question[question].push(operation);
        },

        addBeforeFormSubmitEvent(state, operation) {
            state.beforeFormSubmit.push(operation);
        },

        runStepChangeEvents(state, step) {
            if(!state.step[step]) return;
            for(const event of state.step[step]) {
                event();
            }
        },

        runQuestionChangeEvents(state, question) {
            if(!state.question[question]) return;
            for(const event of state.question[question]) {
                event();
            }
        },

        runBeforeFormSubmitEvents(state) {
            for(const event of state.beforeFormSubmit) {
                event();
            }
        },
    },

    getters: {
    },
};
