export default {
  namespaced: true,

  state: {
    location: {
      zipcode: '',
      latitude: '',
      longitude: '',
    },
  },

  mutations: {
    setLocationValue(state, { key, value }) {
      state.location[key] = value;
    },
  },
};
