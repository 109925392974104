import { datadogRum } from '@datadog/browser-rum';

export default {
    install: () => {
        if (window.Datadog.enabled) {
            datadogRum.init({
                applicationId: window.Datadog.application_id,
                clientToken: window.Datadog.client_token,
                site: 'datadoghq.eu',
                service: window.Datadog.app_name,
                env: window.Datadog.app_env,
                version: window.Datadog.version,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackResources: true,
                trackLongTasks: true,
                trackUserInteractions: true,
            });

            datadogRum.startSessionReplayRecording();
        }
    },
};
