import '../../../frontend-templates/resources/scss/shared.scss';

import { createApp, h } from 'vue';
import { createInertiaApp, Head } from '@inertiajs/vue3';
import VueSelect from 'vue-select';
import WebFont from 'webfontloader';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import { createPinia } from 'pinia';
import { setupDesignSystemComponentLibrary } from 'design-system-component-library';
import { store } from './store';

import datadog from './datadog';
import bugsnag from './bugsnag';

import {usePageStore} from './stores/page';

const pinia = createPinia();

const PrettyNamePlugin = {
    install: (app) => {
        // prettyName
        const prettyNameFn = function (name) {
            if (typeof name !== 'string' || name.length <= 1) {
                return name;
            }

            return (name[0].toUpperCase() + name.substring(1)).replace(/[_|-]/g, ' ');
        };
        app.config.globalProperties.$prettyName = prettyNameFn;
        window.$prettyName = prettyNameFn;

        // loadFont
        let addedFontFamilies = {};
        const addFontFace = function(fontFamily, style, weight, woff2Url, woffUrl) {
            if (addedFontFamilies[`${fontFamily}-${style}-${weight}`]) {
                return;
            }

            const node = document.createElement('style');
            node.innerHTML = ''
                + '@font-face {'
                + `  font-family: "${fontFamily}";`
                + `  font-style: ${style};`
                + `  font-weight: ${weight};`
                + '  font-display: swap;'
                + `  src: url("${woff2Url}") format("woff2"), url("${woffUrl}") format("woff");`
                + '}'
            ;
            document.body.appendChild(node);
            addedFontFamilies[`${fontFamily}-${style}-${weight}`] = true;
        };
        const loadFontFn = function (font, fontType, zoeActiveCallback, googleActiveCallback) {
            if (!font) {
                return;
            }

            if (!fontType) {
                fontType = font.identifier.split('---')[0];
            }

            switch (fontType) {
                case 'zoe':
                    addFontFace(font.identifier, font.style, font.weight, font.woff2, font.woff);

                    WebFont.load({
                        custom: {
                            families: [font.identifier],
                        },
                        active: () => {
                            if (zoeActiveCallback) {
                                (zoeActiveCallback)(font);
                            }
                        },
                    });
                    break;

                case 'google':
                    // google needs "Foo:bar"
                    let googleIdentifier = font.replace('google---', ''); // eslint-disable-line no-case-declarations
                    googleIdentifier += ':300,400,700,italic,bolditalic';
                    WebFont.load({
                        google: {
                            families: [googleIdentifier],
                        },
                        active: () => {
                            if (googleActiveCallback) {
                                (googleActiveCallback)(font);
                            }
                        },
                    });
                    break;
            }
        };
        app.config.globalProperties.$loadFont = loadFontFn;
        window.$loadFont = loadFontFn;
    },
};

createInertiaApp({
    resolve: name => resolvePageComponent(`../../../frontend-templates/templates/${name}/index.vue`, import.meta.glob('./../../../frontend-templates/templates/**/*.vue')),
    title: title => title ? title : 'ZOE',
    async setup({el, App, props, plugin}) {
        const app = await createApp({render: () => h(App, props)})
            // Inertia Components
            .component('InertiaHead', Head)
            .component('VueSelect', VueSelect)

            .use(pinia)
            .use(plugin)
            .use(PrettyNamePlugin)
            .use(store)
            .use(datadog);

        const page = usePageStore();

        app.config.globalProperties.page = page;
        app.config.globalProperties.window = window;

        setupDesignSystemComponentLibrary(props.initialPage.props.page.countryCode);

        bugsnag(app);

        app.mount(el);
    },
});

window.appLoaded = true;
