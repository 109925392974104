export default {
    namespaced: true,

    state: {
        typography: {},
        buttons: {},
        inputs: {},
        containers: {},
    },

    mutations: {
        addTypography(state, { key, option }) {
            state.typography[key] = option;
        },

        addButtonStyling(state, { key, button }) {
            state.buttons[key] = button;
        },

        addInputStyling(state, { key, input }) {
            state.inputs[key] = input;
        },

        addContainerStyling(state, { key, container }) {
            state.containers[key] = container;
        },
    },

    getters: {
        getInputForPlacement: (state) => (key, value) => {
            if (!state.inputs[key]) {
                return !!state.inputs.all[value]?.value;
            }

            return state.inputs[key][value]?.value;
        },
    },
};
