import { createStore } from 'vuex';
import cro from './modules/cro';
import component from './modules/component';
import dialog from './modules/dialog';
import form from './modules/form';
import quiz from './modules/quiz';
import storeLocator from './modules/store-locator';
import styling from './modules/styling';
import user from './modules/user';
import page from './modules/page';

export const store = createStore({
    modules: {
        cro,
        component,
        dialog,
        form,
        quiz,
        storeLocator,
        styling,
        user,
        page,
    },
});
