export default {
    namespaced: true,

    state: () => ({
        organism: {},
        template: {},
    }),

    mutations: {
        setOrganismValue(state, { component, object }) {
            state.organism[component] = { ...state.organism[component], ...object };
        },

        setTemplateValue(state, { component, object }) {
            state.template[component] = {...object};
        },
    },

    getters: {
        getOrganismValue: (state) => (component, key) => {
            for(const [dataKey, data] of Object.entries(state.organism)) {
                if(dataKey === component) return data[key];
            }

            return null;
        },

        getTemplateValue: (state) => (component, key) => {
            for(const [dataKey, data] of Object.entries(state.template)) {
                if(dataKey === component) return data[key];
            }

            return null;
        },
    },
};
