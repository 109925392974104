export default {
    namespaced: true,

    state: {
        id: '',
        url: '',
        baseUrl: '',
        branchData: [],
        type: '',
        translations: {
            homeVisit: '',
            minute: '',
            noBranchCloseBy: '',
            noRouteFound: '',
            oneMomentPlease: '',
            pleaseFillDetails: '',
            pleaseSelectBranch: '',
            visitStore: '',
            prefillConfirmation: '',
        },

        placeholder: '',
        storeOptions: [],
        homeVisitOptions: [],
        prefillStore: false,

        stores: [],
        limit: 0,
        homeVisitLimit: 0,

        storesLatLng: [],
        storesInRangeLatLng: [],
        completeStoreData: [],

        runOnBlur: true,
        running: false,
        runningQuiz: false,

        usedZipcode: '',

        prevalidate: false,

        userLat: '',
        userLng: '',
    },

    mutations: {
        setupBaseData(state, data) {
            for (const [key, value] of Object.entries(data)) {
                state[key] = value;
            }
        },
    },

    getters: {
        getOption: state => storeKey => {
            for (const store of Object.values(state.completeStoreData)) {
                if (store.storeKey.toString() === storeKey.toString()) return store;
            }
        },
    },
};
