export default {
    namespaced: true,

    state: () => ({
        active: '',
        preventClose: false,
    }),

    mutations: {
        show(state, dialog) {
            if (typeof document === 'undefined') return;

            const popup =  document.querySelector('#popup');
            if (typeof(popup) != 'undefined' && popup != null) {
                document.querySelector('#popup').classList.remove('active');
            }

            const body = document.querySelector('body');
            body.classList.add('overflow');

            state.active = dialog;
        },

        hide(state) {
            const body = document.querySelector('body');
            body.classList.remove('overflow');

            state.active = '';
        },

        preventClose(state) {
            state.preventClose = true;
        },
    },

    getters: {
    },
};
