import { getParameters } from '../../../../../frontend-templates/resources/js/vue-flows/helpers/history/v1/history-1.0.0';

export default {
    namespaced: true,

    state: () => ({
        fields: [],
        prefillCity: {
            valid: null,
            cities: [],
        },
        state: 'form',
        prefillZipcode: '',
        attributes: {
            step: 1,
            amountOfSteps: 0,
            countryMask: {},
            countryValidator: {},
            loading: false,
            hiddenLoading: false,
            cancelPreValidate: false,
            checkboxCount: 1,
            consentTags: false,
        },
        smsVerification: {
            hash: '',
            phone: '',
        },
        linkedFormInputs: [],
    }),

    mutations: {
        addField(state, { step = 0, name, data = {} }) {
            if(!state.fields[step]) state.fields[step] = {};

            state.fields[step][name] = data;

            if(!data.preValidate) state.fields[step][name].preValidate = null;
            if(!data.validate) state.fields[step][name].validate = null;

            if(data.uuid && state.linkedFormInputs[data.uuid]) {
                state.fields[step][name].value = state.linkedFormInputs[data.uuid];
            } else if(!data.value) {
                state.fields[step][name].value = '';
            }
        },

        setAttribute(state, { key, value }) {
            state.attributes[key] = value;
        },

        setFieldData(state, { name, key, value }) {
            for(const step of Object.values(state.fields)) {
                for(const [fieldKey, field] of Object.entries(step)) {
                    if(fieldKey === name) {
                        field[key] = value;

                        if(getParameters()[fieldKey] && this.state.page.variables[fieldKey]) return;

                        if(key === 'value') {
                            this.commit('page/setVariable', { key: fieldKey, value });
                        }
                    }
                }
            }
        },

        addLinkedFormInput(state, { uuid, value }) {
            state.linkedFormInputs[uuid] = value;
        },

        setPrefillCity(state, { valid, cities = [] }) {
            state.prefillCity.valid = valid;
            state.prefillCity.cities = cities;
        },

        setPrefillZipcode(state, zipcode) {
            state.prefillZipcode = zipcode;
        },

        setFormState(state, formState) {
            state.state = formState;
        },
    },

    getters: {
        getField: (state) => (name) => {
            for(const step of Object.values(state.fields)) {
                for(const [key, field] of Object.entries(step)) {
                    if(key === name) return field;
                }
            }

            return null;
        },

        getFieldData: (state) => (dataKey, name) => {
            for(const step of Object.values(state.fields)) {
                for(const [key, field] of Object.entries(step)) {
                    if(key === name) return field[dataKey];
                }
            }

            return null;
        },

        getFormData: (state) => () => {
            const data = [];

            for(const step of Object.values(state.fields)) {
                for(const [key, field] of Object.entries(step)) {
                    data.push({ name: key, ...field });
                }
            }

            return data;
        },

        getFieldStep: (state) => (name) => {
            for(const [step, fields] of Object.entries(state.fields)) {
                if(fields[name]) return step;
            }

            return null;
        },

        fieldExists: state => name => {
            for(const step of Object.values(state.fields)) {
                for(const key of Object.keys(step)) {
                    if(key === name) return true;
                }
            }

            return false;
        },

        getFieldWithUuid: state => uuid => {
            for(const step of Object.values(state.fields)) {
                for(const [key, field] of Object.entries(step)) {
                    if(field.uuid && field.uuid === uuid) return key;
                }
            }

            return null;
        },
    },
};
