export default {
    namespaced: true,

    state: {
        wrapper: '',
        pixelStep: 0,
        step: 1,
        currentStep: {},
        previousStep: 1,
        totalSteps: 0,
        countryCode: '',
        language: '',
        recaptchaKey: '',
        customer: '',
        data: {},
        pageGroupType: '',
        productType: '',
        languageFile: {},
        translations: {},
        zipcodeLists: null,
        routes: {},
        notificationText: '',
        variables: {},
        isLanding: true,
        isPreview: false,
        deviceType: '',
        startCtaIsActive: false,
        browserBackButton: true,
        needsTrustedForm: false,
        scrollTarget: 'body',
        autoScroll: true,
        steps: [],
        cookieConsent: '{"ad_storage":"denied","analytics_storage":"denied","ad_personalization":"denied","ad_user_data":"denied","functionality_storage":"denied","personalization_storage":"denied","security_storage":"denied"}',
    },

    mutations: {
        setValue(state, { key, value }) {
            state[key] = value;
        },

        increasePixelStep(state, { label, pixelStep = 1 }) {
            if (state.isPreview) return;
            state.pixelStep += pixelStep;
            !!window.firePixelOnStepChange && window.firePixelOnStepChange(label, state.pixelStep);
        },

        decreasePixelStep(state, { label, pixelStep = 1 }) {
            if (state.isPreview) return;
            state.pixelStep -= pixelStep;

            if (state.pixelStep === 1) {
                label = 'Start';
            }

            !!window.firePixelOnStepChange && window.firePixelOnStepChange(label, state.pixelStep);
        },

        setStep(state, step) {
            if (typeof step === 'string') {
                state.step = state.steps.indexOf(step) + 1;
                return;
            }

            state.step = step;
        },

        setCountryCode(state, countryCode) {
            state.countryCode = countryCode;
        },

        setLanguage(state, language) {
            state.language = language;
        },

        setCustomer(state, customer) {
            state.customer = customer;
        },

        setData(state, page) {
            state.data = { ...page };
        },

        setLanguageFile(state, languageFile) {
            state.languageFile = languageFile;
        },

        setTranslations(state, translations) {
            state.translations = translations;
        },

        setRoutes(state, routes) {
            state.routes = routes;
        },

        setPageGroupType(state, pageGroupType) {
            state.pageGroupType = pageGroupType;
        },

        setVariable(state, { key, value }) {
            state.variables[key] = value;
        },

        setPreview(state, value) {
            if (value.includes('preview')) {
                state.isPreview = true;
            }
        },

        setZipcodeLists(state, zipcodeLists) {
            if (zipcodeLists === null) return;

            for (const zipcodeList of Object.values(zipcodeLists)) {
                zipcodeList.lists.map(list => {
                    list.zipcodes = list.zipcodes.toUpperCase().split(',');
                });
            }

            state.zipcodeLists = zipcodeLists;
        },
    },

    getters: {
        getStepFromString: state => step => {
            if (state.steps.length === 1) {
                return 2;
            }

            return state.steps.findIndex(item => item.key === step) + 1;
        },

        getFromData: state => path => {
            const locations = path.toString().split('.');
            let element = state.data;
            let error = false;

            locations.forEach(location => {
                if (element[location] === undefined) {
                    error = true;
                    return;
                }

                element = element[location];

            });

            if (error) return null;

            return element;

        },

        getRoute: state => route => {
            return state.routes[route];
        },

        getZipcodeListBasedOnId: state => listId => {
            if (!state.zipcodeLists) return null;

            return state.zipcodeLists[listId];
        },
    },
};
